import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { t, translate } from '../../utils/ReactSwitchLangWrapper';
import { setScreenReaderAlert, moveFocusTo } from '../../utils/Accessibility';
import style from '../../styles/Alert.module.scss';

function ErrorAlert({
  msg,
  fullWidth,
  forgotPassword,
  children,
  className = '',
}) {
  useEffect(() => {
    if (msg) {
      setScreenReaderAlert(forgotPassword ? `${msg} ${t('ScreenReaderAlert_ForgotPassword')}` : msg);
      moveFocusTo('error-alert');
    }
  }, [msg]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!msg) return null;
  return (
    <div id="error-alert" className={`${style.alert} ${style.error} ${fullWidth ? ` ${style.full} ` : ''} ${className}`}>
      <FontAwesomeIcon icon={faExclamationCircle} className={style.textDanger} />
      <div className={style.alertText}>
        <p>{msg}</p>
        {children}
      </div>
    </div>
  );
}

export default translate(ErrorAlert);
